require('../../css/frontoffice.css');

import * as $ from 'jquery';

window.$ = window.jQuery = $;

require('bootstrap');

const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);
window.Routing = Routing;

export default (function () {
    $(document).ready(function() {

    });
}());

